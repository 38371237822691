import {useState, useEffect} from 'react'
import "../css/header.scss"

const Header = () => {
    return(
        <div className="header">
            {/* <img src={require("../../img/sandpock-logo@2x.png")} alt="logo" /> */}
            {/* <img src="" alt="" /> */}
        </div>
    )
}

export default Header