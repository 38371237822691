import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import "../../../css/stagetwo.scss"

const img = [
    require("../../../../img/stage-two-chinese@2x.png"),
    require("../../../../img/stage-two-english@2x.png")
]

const StageTwo = () => {
    const language = useSelector((state) => state.language.language);
    const [ stageTwoImgIndex, setStageTwoImgIndex ] = useState(0);
    const [ stageTwoContext, setStageTwoContext] = useState('加入 Discord。');
    const [ stageTwoBtnContext, setStageTwoBtnContext ] = useState('連動');

    useEffect(()=>{
        if(language === 'ENGLISH'){
            setStageTwoImgIndex(1);
            setStageTwoContext('Join Sandpock official discord.');
            setStageTwoBtnContext('Join')

        }
        if(language === 'CHINESE'){
            setStageTwoImgIndex(0);
            setStageTwoContext('加入 Discord');
            setStageTwoBtnContext('加入')
        }
    })

    return(
        <div className='section-two'>
            <img src={img[stageTwoImgIndex]} alt="stage-two" />
            <p className='stagetwo-content'>{stageTwoContext}</p>
            <button id="join-discord">{stageTwoBtnContext}</button>
        </div>
    )
}

export default StageTwo