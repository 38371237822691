import {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import "../../css/connect-wallet.scss";
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
  } from 'wagmi'

  const ConnectWallet = () => {

    const language = useSelector((state)=>state.language.language);

    const [ changeLanguage, setChangeLanguage ] = useState('連接錢包');

    const { address, connector, isConnected } = useAccount()
    const { data: ensAvatar } = useEnsAvatar({ address })
    const { data: ensName } = useEnsName({ address })
    const { connect, connectors, error, isLoading, pendingConnector } =
      useConnect()
    const { disconnect } = useDisconnect()

    useEffect(()=>{
      if(language==="ENGLISH"){
        setChangeLanguage('CONNECT WALLET');
      }
      if(language==="CHINESE"){
        setChangeLanguage('連接錢包');
      }
    })
   
    if (isConnected) {
        const simplify_address = address.substring(0,5)+"..."+address.substring(address.length-5);
        return (
            <div>
          {/* <div>Connected to {connector.name}</div> */}
          <button id="is-connect-wallet" onClick={disconnect}>
            <img id="metamask" src={require("../../../img/metamask@2x.png")} alt="" />
            {simplify_address}
          </button>
        </div>
      )
    }
   
    return (
      <div className="connect-wallet">
        {connectors.map((connector) => (
          <button disabled={!connector.ready} key={connector.id} id="connect-wallet"
            onClick={() => {connect({ connector })}}>
              {changeLanguage}
          </button>
        ))}
      </div>
    )
  }

  export default ConnectWallet;