import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Mint, Info, Foundation, Bulb, WaterMark, GetMousePosition} from "../../sandpock_index";
import "../../css/main.scss";

const MainVission = ()=>{
    const position = GetMousePosition();

    return(
        <div id="main-container">
            <div id="context">
                <Bulb />
                <Foundation />
                <Mint />
                <WaterMark />
                <Info />
                <p className="coordinate">
                    {position.x}:{position.y}
                </p>
                
            </div>
        </div>
    )
}

export default MainVission