import { combineReducers } from 'redux'
import lightBulb00CounterReducer from './lightBulb00CounterReducer';
import lightBulb01CounterReducer from './lightBulb01CounterReducer';
import lightBulb02CounterReducer from './lightBulb02CounterReducer';
import lightBulb03CounterReducer from './lightBulb03CounterReducer';
import lightBulbIndexReducer from './lightBulbIndexReducer';
import changeLanguageReducer from './changeLanguageReducer';

const rootReducers = combineReducers({
    lightBulbIndex: lightBulbIndexReducer,
    lightBulb00Counter: lightBulb00CounterReducer,
    lightBulb01Counter: lightBulb01CounterReducer,
    lightBulb02Counter: lightBulb02CounterReducer,
    lightBulb03Counter: lightBulb03CounterReducer,
    language: changeLanguageReducer,
})

export default rootReducers;