import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "../../css/info.scss";

const Info =()=>{

    const language = useSelector((state)=>state.language.language);
    const [info, setInfo] = useState('Sandpock PASS是數量為3000個獨特的NFT。持有Sandpock PASS NFT可獲得特殊人物角色（PFP）與房間（Room）。此階段PASS是採用特有的《刮刮樂》元素並進行任務，任務完成後NFT會產生改變；全階段完成後將獲得下一階段NFT憑證。')

    useEffect(()=>{
        if(language === 'ENGLISH'){
            setInfo('Sandpock PASS, a collection of 3,000 special NFTs with “scratchcard” element. By completing given tasks, the PASS will be scratched off part by part. After competion of all tasks, holder would use the PASS as whitelist in order to get a free mint of PFP.');
        }
        if(language === 'CHINESE'){
            setInfo('Sandpock PASS是數量為3000個獨特的NFT。持有Sandpock PASS NFT可獲得特殊人物角色（PFP）與房間（Room）。此階段PASS是採用特有的《刮刮樂》元素並進行任務，任務完成後NFT會產生改變；全階段完成後將獲得下一階段NFT憑證。');
        }
    })

    return(
        <div className="info">
            <p className="info-content">{info}</p>
        </div>
    )
}

export default Info