const initialState={
    counter: 0
}

const lightBulb01CounterReducer=(state=initialState, action)=>{
    switch(action.type){
        case 'LIGHTBULB_01_INCREMENT':
            if(state.counter<9){
                return {
                    ...state,
                    counter: state.counter + 1
                }
            }else{
                return {
                    ...state,
                    counter: state.counter
                } 
            }
        case 'LIGHTBULB_01_DECREMENT':
            if(state.counter>0){
                return {
                    ...state,
                    counter: state.counter - 1
                }
            }else{
                return {
                    ...state,
                    counter: state.counter
                }
            }
        default:
            return state;
    }
}

export default lightBulb01CounterReducer;