import {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import "../../css/mint.scss"
import Axios from '../Axios'

let btnImg;
const img = [
    require('../../../img/mint-cht-enable-default@2x.png'),
    require('../../../img/mint-eng@2x.png')
]

const Mint = () =>{

    const language = useSelector((state)=>state.language.language);

    const lightbulb_00_Counter = useSelector((state) => state.lightBulb00Counter.counter);
    const lightbulb_01_Counter = useSelector((state) => state.lightBulb01Counter.counter);
    const lightbulb_02_Counter = useSelector((state) => state.lightBulb02Counter.counter);
    const lightbulb_03_Counter = useSelector((state) => state.lightBulb03Counter.counter);

    async function checkIfMint(){
        const data = await Axios.get()
            .then((response)=> console.log(response))
            .catch((error)=>console.log(error))
    }
    
    useEffect(()=>{
        btnImg = document.getElementById('mint-button');
        if(language === 'ENGLISH'){

        }
        if(language === 'CHINESE'){

        }
    })

    return (
        <div className="mint">
            <button id="mint-button" onClick={checkIfMint}></button>
        </div>
    )
}

export default Mint;