import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../css/switch.scss'

const switchActiveColor = '#5445AE';
const switchUnactiveColor = '#242424';

let mission, pass, missionSwitchButton, passSwitchButton;

const Switch = () =>{

    const language = useSelector((state)=>state.language.language);

    const [buttonOne, setButtonOne] = useState('Pass主頁');
    const [buttonSecond, setButtonSecond] = useState('任務系統');

    useEffect(()=>{
        pass = document.getElementById('main-container');
        mission = document.getElementById('mission');
        passSwitchButton = document.getElementById('pass-page');
        missionSwitchButton = document.getElementById('mission-page');
        if(language === "ENGLISH"){
            setButtonOne('Home Page');
            setButtonSecond('Mission System');
        }
        if(language === "CHINESE"){
            setButtonOne('Pass主頁');
            setButtonSecond('任務系統');
        }
    })

    function switchToMissionPage(){
        pass.style.display = 'none';
        passSwitchButton.style.background = switchUnactiveColor;
        mission.style.display = 'block';
        missionSwitchButton.style.background = switchActiveColor;
        // if(window.innerWidth < 500){
            
        // }
    }

    function switchToPassPage() {
        mission.style.display = 'none';
        missionSwitchButton.style.background = switchUnactiveColor;
        pass.style.display = 'block';
        passSwitchButton.style.background = switchActiveColor;
        if(window.innerWidth < 500){

        }
    }

    return(
        <div className="switch-button">
            <button id="pass-page" onClick={switchToPassPage}>{buttonOne}</button>
            <button id="mission-page" onClick={switchToMissionPage}>{buttonSecond}</button>
        </div>
    )
}

export default Switch;