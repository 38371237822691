import { useState, useEffect} from 'react'
import { ConnectWallet, ChangeLanguage, Switch, Footer } from '../../sandpock_index';
import "../../css/nav.scss";

let menu;

const NavBar = () => {

    const [menuState, setMenuState] = useState(false);

    useEffect(()=>{
        menu=document.getElementById('menu-popup');
    })

    function showMenu(){
        if(menuState===false){
            menu.style.display="block";
            setMenuState(true);
        }else if(menuState===true){
            menu.style.display="none";
            setMenuState(false);
        }
    }

    return(
        <div className="nav-container">
            <nav className="Nav">
                <Switch />
                <div className="section-connect-switch">
                    <ConnectWallet />
                    <ChangeLanguage />
                </div>
            </nav>
            <nav className="mini-nav">
                <div className="mini-nav-logo"></div>
                <button id="menu" onClick={showMenu}>
                    <div className="menu-bar"></div>
                </button>
            </nav>
            <div id="menu-popup">
                <div className="menu-popup-container">
                    <div className="content">
                        <hr></hr>
                        <Switch />
                        <hr></hr>
                        <ConnectWallet />
                        <hr></hr>
                        <ChangeLanguage />
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar;