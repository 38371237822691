import { useState, useEffect } from 'react'
import WaterMark from './WaterMark';
import "../../css/foundation.scss"
import { useSelector, useDispatch } from 'react-redux'
import { Bulb } from '../../sandpock_index';


const foundation = require("../../../img/nixie-tube@2x.png");

const FoundationButton = ()=>{

    const lightbulbIndex = useSelector((state)=> state.lightBulbIndex.index);
    const lightbulb_00_Counter = useSelector((state) => state.lightBulb00Counter.counter);
    const lightbulb_01_Counter = useSelector((state) => state.lightBulb01Counter.counter);
    const lightbulb_02_Counter = useSelector((state) => state.lightBulb02Counter.counter);
    const lightbulb_03_Counter = useSelector((state) => state.lightBulb03Counter.counter);
    const dispatch = useDispatch();

    const bulbArray = [
        {id: 0, count:0},
        {id: 1, count:0},
        {id: 2, count:0},
        {id: 3, count:0}
    ]

    const[bulbCount, updateBulbCount] = useState(bulbArray);
    const[index, setIndex] = useState(0);

    function increase(event){
        event.stopPropagation();
        dispatch({type:`LIGHTBULB_0${lightbulbIndex}_INCREMENT`});
    }

    function decrease(event){
        event.stopPropagation();
        dispatch({type:`LIGHTBULB_0${lightbulbIndex}_DECREMENT`});
    }

    function next(event){
        event.stopPropagation();
        if(lightbulbIndex<3){
            dispatch({type:"LIGHTBULB_INDEX_INCREMENT"});
        }
    }

    function back(event){
        event.stopPropagation();
        if(lightbulbIndex>0){
            dispatch({type:"LIGHTBULB_INDEX_DECREMENT"});
        }
    }

    return(
        <div className='foundation'>
            {/* <WaterMark /> */}
            {/* <div className='watermark'></div> */}
                    <div className="controller">
                        <img src={foundation} alt="" id="holder"/>
                        
                        <div className="light-control-button">
                            <button id="add" onClick={increase}></button>
                            <button id="minus" onClick={decrease}></button>
                            <button id="left" onClick={back}></button>
                            <button id="right" onClick={next}></button>
                    </div>
            </div>
        </div>
    )
}

export default FoundationButton