const initialState = {
    index:0
}

const lightBulbIndexReducer = (state = initialState, action) =>{
    switch(action.type){
        case 'LIGHTBULB_INDEX_INCREMENT':
            return{
                ...state,
                index: state.index + 1
            }
        case 'LIGHTBULB_INDEX_DECREMENT':
            return{
                ...state,
                index: state.index - 1
            }
        default:
            return state
    }
}

export default lightBulbIndexReducer;