import { useState, useEffect } from "react";

const GetMousePosition = () => {
    const [position, setPosition] = useState({x:0, y:0});

    useEffect(()=>{
        const setFromEvent = (e) => {
            e.stopPropagation()
            setPosition({x: e.clientX, y: e.clientY})
        };
        window.addEventListener("mousemove", setFromEvent);
        return() => {
            window.removeEventListener('mousemove', setFromEvent);
        };
    }, []);
    return position;
}

export default GetMousePosition;