import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import "../../../css/stagethree.scss"

const img = [
    require("../../../../img/stage-three-chinese@2x.png"),
    require("../../../../img/stage-three-english@2x.png")
]

const StageThree = () => {
    const language = useSelector((state) => state.language.language);
    const [ stageThreeImgIndex, setStageThreeImgIndex ] = useState(0);
    const [ stageThreeContext, setStageThreeContext] = useState('邀請朋友加入 Discord。');
    const [ stageThreeBtnContext, setStageThreeBtnContext ] = useState('邀請');

    useEffect(()=>{
        if(language === 'ENGLISH'){
            setStageThreeImgIndex(1);
            setStageThreeContext('Join Sandpock official discord.');
            setStageThreeBtnContext('Join')

        }
        if(language === 'CHINESE'){
            setStageThreeImgIndex(0);
            setStageThreeContext('邀請朋友加入 Discord');
            setStageThreeBtnContext('邀請')
        }
    })

    return(
        <div className='section-three'>
            <img src={img[stageThreeImgIndex]} alt="stage-three" />
            <p className='stagethree-content'>{stageThreeContext}</p>
            <button id="invite-discord">{stageThreeBtnContext}</button>
        </div>
    )
}

export default StageThree