import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from "redux";
import { Home, Landing, Login, Signup } from "./screens";
import { MainVission, MissionIndex, rootReducers, NavBar, Header} from "./sandpock/sandpock_index";
import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'
 
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
 
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import './App.scss'
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: 'yourAlchemyApiKey' }), publicProvider()],
)
 
// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
  ],
  provider,
  webSocketProvider,
})

const App = () => {

  const store = createStore(rootReducers);

  const [user, setUser] = useState({});
  const [page, setPage] = useState(0, 0);

  useEffect(() => {
    const theUser = localStorage.getItem("user");

    if (theUser && !theUser.includes("undefined")) {
      setUser(JSON.parse(theUser));
    }
  }, []);
  return (
    <Provider store={store}>
      <WagmiConfig client = {client}>
        <div className="container">
          <div className="nav-container">
            <Header />
            <NavBar />
          </div>
          <MainVission />
          <MissionIndex />
        </div>
      </WagmiConfig>
    </Provider>
  );
};

export default App;
