import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import "../../../css/stageone.scss"

const img = [
    require("../../../../img/stage-one-chinese@2x.png"),
    require("../../../../img/stage-one-english@2x.png")
]

const StageOne = () => {
    const language = useSelector((state) => state.language.language);
    const [ stageOneImgIndex, setStageOneImgIndex ] = useState(0);
    const [ stageOneContext, setStageOneContext ] = useState('綁定 Google 帳戶，來完成任務，完成後獲得刮了一格的 Pass。');
    const [ stageOneBtnContext, setStageOneBtnContext ] = useState('連動');
    
    useEffect(()=>{
        if(language === 'ENGLISH'){
            setStageOneImgIndex(1);
            setStageOneContext('Connect to your google account.');
            setStageOneBtnContext('Connect');
        }
        if(language === 'CHINESE'){
            setStageOneImgIndex(0);
            setStageOneContext('綁定 Google 帳戶，來完成任務，完成後獲得刮了一格的 Pass。')
            setStageOneBtnContext('連動')
        }
    })

    return(
        <div className='section-one'>
            <img src={img[stageOneImgIndex]} alt="stage-one" />
            <p className='stageone-content'>{stageOneContext}</p>
            <button id="connect-google">{stageOneBtnContext}</button>
        </div>
    )
}

export default StageOne