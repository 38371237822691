import "../../css/mission.scss"
import { useState, useEffect } from 'react'
import { StageOne, StageTwo, StageThree, TaskFooter } from '../../sandpock_index'


const MissionIndex = () => {
    return(
        <div>
            <div id="mission">
                <StageOne />
                <StageTwo />
                <StageThree />
                <TaskFooter />
            </div>
        </div>
    )
}

export default MissionIndex