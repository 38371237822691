import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import "../../css/change-language.scss";

const ChangeLanguage = () => {

    const dispatch = useDispatch();

    function SwitchToChinese() {
        dispatch({type:'SWITCH_TO_CHINESE'})
    }

    function SwitchToEnglish() {
        dispatch({type: 'SWITCH_TO_ENGLISH'})
    }

    return (
        <div className="language-switch">
            <button id="english" onClick={SwitchToEnglish}>
                <img src={require("../../../img/english@2x.png")} alt="english" id="english-img"/>
            </button>
            <p className="slash">/</p>
            <button id="chinese" onClick={SwitchToChinese}>
                <img src={require("../../../img/chinese@2x.png")} alt="chinese" id="chinese-img"/>
            </button>
        </div>
    )
}

export default ChangeLanguage