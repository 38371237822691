import {useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import "../../css/light.scss"

const lightArray =[
    require("../../../img/light00@2x.png"),
    require("../../../img/light01@2x.png"),
    require("../../../img/light02@2x.png"),
    require("../../../img/light03@2x.png"),
    require("../../../img/light04@2x.png"),
    require("../../../img/light05@2x.png"),
    require("../../../img/light06@2x.png"),
    require("../../../img/light07@2x.png"),
    require("../../../img/light08@2x.png"),
    require("../../../img/light09@2x.png"),
];

const bulbField = [
    document.getElementById('bulb-00'),
    document.getElementById('bulb-01'),
    document.getElementById('bulb-02'),
    document.getElementById('bulb-03'),
];

const LightBulb = (update) =>{

    const lightbulb_00_Counter = useSelector((state) => state.lightBulb00Counter.counter);
    const lightbulb_01_Counter = useSelector((state) => state.lightBulb01Counter.counter);
    const lightbulb_02_Counter = useSelector((state) => state.lightBulb02Counter.counter);
    const lightbulb_03_Counter = useSelector((state) => state.lightBulb03Counter.counter);
    
    const [bulb, updateBulb] = useState([bulbField]);
    const [bulbArray, updateArray] = useState([0,0,0,0]);

    const [bulb00, setBulb00] = useState(0);
    const [bulb01, setBulb01] = useState(2);
    const [bulb02, setBulb02] = useState(0);
    const [bulb03, setBulb03] = useState(0);
    
    function setBulb(){
        update(function(data) {
            console.log(data);
            setBulb00(data[0]);
            setBulb01(data[1]);
            setBulb02(data[2]);
            setBulb03(data[3]);
        });
    }

    return(
        <div className='lightbulb-container'>
            <div className='light'>
                <img src={lightArray[lightbulb_00_Counter]} alt="" id="bulb-00"/>
                <img src={lightArray[lightbulb_01_Counter]} alt="" id="bulb-01"/>
                <img src={lightArray[lightbulb_02_Counter]} alt="" id="bulb-02"/>
                <img src={lightArray[lightbulb_03_Counter]} alt="" id="bulb-03"/>
            </div>
        </div>
    )
}

export default LightBulb
