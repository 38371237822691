import _axios from 'axios'

const Axios = (baseURL) => {
    const instance = _axios.create({
        baseURL: baseURL || 'https://api.llama.fi/protocols',
        timeout: 1000,
    });
    return instance
}

export default Axios();