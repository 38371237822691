import "../../css/watermark.scss"

const WaterMark = () => {
    return(
        <div className="watermark">
            {/* <img src={require("../../../img/watermark@2x.png")} alt="" /> */}
        </div>
    )
}

export default WaterMark