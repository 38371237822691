const initialState = {
    language: 'CHINESE'
}

const changeLanguageReducer = (state=initialState, action)=>{
    switch(action.type){
        case 'SWITCH_TO_ENGLISH':
            return{
                ...state,
                language: 'ENGLISH'
            }
        case 'SWITCH_TO_CHINESE':
            return{
                ...state,
                language:'CHINESE'
            }
        default:
            return state
    }
}

export default changeLanguageReducer;