import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import "../../../css/task-footer.scss"


const TaskFooter = ()=>{

    const language = useSelector((state)=>state.language.language)

    const [firstInfo, setFirstInfo] = useState('第一階段示意');
    const [secondInfo, setSecondInfo] = useState('第二階段示意');
    const [simplifyFirstInfo, setSimplifyFirstInfo] = useState('第一階段');
    const [simplifySecondInfo, setSimplifySecondInfo] = useState('第二階段');

    useEffect(()=>{
        if(language === 'ENGLISH'){
            setFirstInfo('MISSION ONE');
            setSecondInfo('MISSION TWO');
            setSimplifyFirstInfo('MISSION ONE');
            setSimplifySecondInfo('MISSION TWO');
        }
        if(language === 'CHINESE'){
            setFirstInfo('第一階段示意');
            setSecondInfo('第二階段示意');
            setSimplifyFirstInfo('第一階段');
            setSimplifySecondInfo('第二階段');
        }
    })
    
    return(
        <div className="footer">
            <button id="first-stage-info">{firstInfo}</button>
            <button id="second-stage-info">{secondInfo}</button>
            <button id="first-stage-info-mini">{simplifyFirstInfo}</button>
            <button id="second-stage-info-mini">{simplifySecondInfo}</button>
        </div>
    )
}

export default TaskFooter